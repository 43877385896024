@import "colours";
@import "typography";
@import "variables";

.pulse-table-header-item {
  font-family: $header-font;
}

.pulse-table-row-item {
  font-family: $body-font;
}

.expandable-section {
  overflow: hidden;
  transition: max-height $animation-duration / 2 ease-in-out;
  position: relative;
}

.expandable-section.open {
  max-height: 700px;
  overflow: hidden auto;
}

.expandable-section.closed {
  max-height: 0;
  overflow: hidden;
}

.expandable-table-bottom {
  border-bottom: 10px solid $mc-blue-01;
}

.expandable-odd-row:hover,
.expandable-even-row:hover {
  cursor: pointer;
}

.expandable-even-row {
  background-color: $mc-white-01;
}
.expandable-odd-row {
  background-color: $off-white
}

.expanded {
  box-shadow: 0 7px 2px $mc-grey-04;
  transition: all $animation-duration / 2 ease-in-out;
}

.expanded-under {
  transition: all $animation-duration / 2 ease-in-out;
  background: linear-gradient(rgba(0, 0, 0, 0), $mc-grey-04);
}

.inner-table-spacer {
  width: 100%;
  border-bottom: 2px solid $mc-grey-05;
}
