@import "colours";
@import "typography";
@import "variables";

.read-only {
  .assignment-base {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    float: left;
    margin: 4px 3px 0 0;
    border-color: transparent;
  }

  .assignment-base.free {
    background-image: linear-gradient(to right, rgb(200, 200, 200), rgb(230, 230, 230));
  }

  .assignment-base.waitingAccept {
    background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
  }

  .assignment-base.accepted {
    background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
  }

  .assignment-base.attended {
    background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
  }

  .assignment-base.unassigned {
    background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);
  }

  .staff-member {
    font-family: $body-font;
    color: $mc-blue-01;
    text-transform: capitalize;
    font-size: 1em;
    float: left;
  }
}

.admin {
  .assignment-base {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    float: left;
    margin: 4px 3px 5px 0;
    border: 1px solid #aaaaaa;
  }

  .assignment-base:hover {
    cursor: pointer;
    border: 2px solid $mc-grey-02;
    width: 20px;
    height: 20px;
    margin: 4px 3px 0 0;
    transition: all $animation-duration ease;
  }

  .assignment-base.free {
    background-image: linear-gradient(to right, rgb(200, 200, 200), rgb(230, 230, 230));
  }

  .assignment-base.waitingAccept {
    background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
  }

  .assignment-base.accepted {
    background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
  }

  .assignment-base.attended {
    background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
  }

  .staff-email,
  .staff-member {
    font-family: $body-font;
    color: $mc-blue-01;
    float: left;
  }

  .staff-member {
    text-transform: capitalize;
    font-size: 1em;
  }
  .staff-email {
    text-transform: none;
    font-size: .9em;
  }

  .assignment-wrapper{
    width: 100%;
  }
}

.add {
  background-color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  float: left;
  margin: 4px 3px 5px 0;
  border-style: solid;
  border-width: 1px;
  border-color: $mc-blue-01;
  position: relative;

  .cross-vertical {
    position: absolute;
    transform: translate(6px, 2px);
    width: 1px;
    height: 9px;
    background-color: $mc-blue-01;
  }

  .cross-horizontal {
    position: absolute;
    transform: translate(2px, 6px);
    text-align: center;
    width: 9px;
    height: 1px;
    background-color: $mc-blue-01;
  }
}

.add:hover {
  width: 19px;
  height: 19px;
  margin: 4px 3px 1px 0;
  border-width: 1px;
  transition: all $animation-duration ease;

  .cross-vertical {
    position: absolute;
    transform: translate(8px, 2px);
    width: 1px;
    height: 13px;
    background-color: $mc-blue-01;
    transition: all $animation-duration ease;
  }

  .cross-horizontal {
    position: absolute;
    transform: translate(2px, 8px);
    text-align: center;
    width: 13px;
    height: 1px;
    background-color: $mc-blue-01;
    transition: all $animation-duration ease;
  }
}
