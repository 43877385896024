@import "colours";

.horizontal-bar-base {
  width: 100%;
  height: 30px;
  background-color: $mc-grey-01;
  z-index: 3;
}

.horizontal-bar-value {
  transition: all ease-in-out 1s;
  animation-delay: 0.5s;
  height: 100%;
  z-index: 2;
}

.horizontal-bar-axis {
  border-left: 1px solid transparent;
}

.horizontal-bar-chart-container {
  padding: 10px 0;
}

.horizontal-bar-axis-label {
  margin: 0;
  padding: 5px 0 0 5px;
  color: $mc-white-01;
  font-size: 0.85rem;
}

.horizontal-bar-unassigned {
  background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);
}

.horizontal-bar-waitingAccept {
  background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
}

.horizontal-bar-accepted {
  background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
}

.horizontal-bar-briefed {
  background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
}
