@import "colours";
@import "typography";
@import "variables";

.mobile-dates {
  transition: all ease-in-out $animation-duration;
  display: inline-block;
  color: $mc-white-01;
  width: 160px;
  text-align: center;
  padding: 20px 10px;
}

.mobile-dates.selected {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;
}

.mobile-dates-container {
  background-color: $mc-blue-01;
}

.horizontal-calendar-wrapper {
  position: sticky;
  top: 0;
}

.horizontal-calendar {
  background-color: $mc-blue-01;
  padding: 20px 0 0;
}

.horizontal-calendar-actions-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.horizontal-calendar-action-arrows {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  min-width: 30px;
}

.horizontal-calendar-action-arrow {
  color: $mc-white-01;
}

.horizontal-calendar-current-month {
  flex-grow: 7;
}

.visible-month-and-year {
  color: $mc-white-01;
  padding: 0 20px;
  font-family: $header-font;
  min-height: 24px;
  text-align: center;
}

.horizontal-calendar-date {
  border-bottom-color: transparent;
  border-bottom-width: 2px;
  padding: 0 30px;
}

.horizontal-calendar-date.active {
  background-color: $mc-yellow-01;
}

.horizontal-date-wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  height: auto;
}

.horizontal-calendar-date-text,
.horizontal-calendar-day-text {
  text-align: center;
  color: $mc-white-01;
}

.horizontal-calendar-date-text {
  font-size: 22px;
  font-family: $body-font;
}

.horizontal-calendar-day-text {
  font-size: 12px;
  margin-top: 10px;
  font-family: $header-font;
}

.horizontal-calendar-date-text.active,
.horizontal-calendar-day-text.active {
  color: $mc-blue-01;
}
