@import "colours";
@import "typography";
@import "variables";

.dash-tile-section-title {
  margin: 15px;
}
.dash-tiles-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.dash-tile {
  width: calc(25% - 30px);
  margin: 15px;
  background-color: $mc-white-01;
  border: 2px solid white;
  border-radius: 28px;
  position: relative;
  transition: all ease-in-out $animation-duration;

  &:hover {
    cursor: pointer;
    background-color: $mc-grey-02;
  }
}

.dash-tile-edit-icon-wrapper.hide {
  display: none;
}

.dash-tile-edit-icon-wrapper.show {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: $mc-blue-01;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ease-in-out $animation-duration;
  height: 40px;
  width: 40px;


  &:hover {
    cursor: pointer;
    background-color: $mc-dark-blue-hover;
  }
}

.dash-tile-edit-icon {
  color: $mc-white-01;
  padding: 3px;
}

.dash-tile-header {
  font-family: $header-font;
  text-align: center;
  margin: 15px 0 5px 0;
}

.dash-tile-button-group {
  display: flex;
  justify-content: end;
  padding: 15px 5px;
}

.tile-day-compliance-wrapper {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tile-day-compliance {
  width: 33%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tile-day-compliance-date {
  font-family: $header-font;
  font-size: 12px;
  text-align: center;
}

.day-compliance-base {
  background: $mc-grey-08;
  border: 2px solid white;
  border-radius: 10px;
  width: 75%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-compliance-base.compliant {
  background-image: linear-gradient(to right, lighten($vibrant-green, 10%), lighten($vibrant-green-sec, 10%));
  border: 2px solid white;
  border-radius: 10px;
  width: 75%;
  height: 75%;
}

.day-compliance-base.not-compliant {
  background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);
  border: 2px solid white;
  border-radius: 10px;
  width: 75%;
  height: 75%;
}

.day-compliance-base.partial-compliant {
  background-image: linear-gradient(to right, $vibrant-orange, $vibrant-orange-sec);
  border: 2px solid white;
  border-radius: 10px;
  width: 75%;
  height: 75%;
}


.day-compliance-icon {
  color: white;
}

.dash-tile-button {
  border: transparent;
  background-color: $mc-white-01;
  color: $mc-blue-01;

  transition: all ease-in-out $animation-duration;

  &:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.dash-tile-button.selected {
  border: $mc-blue-01;
  background-color: $mc-blue-01;
  color: $mc-grey-05;
}

@media only screen and (max-width: 1200px) {
  .dash-tile {
    width: calc(33% - 30px);
  }
}

@media only screen and (max-width: 950px) {
  .dash-tile {
    width: calc(50% - 30px);
  }
}

@media only screen and (max-width: 660px) {
  .dash-tile {
    width: calc(100% - 30px);
  }
}
