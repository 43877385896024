@import "colours";

.stat-item-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
  grid-gap: 90px;
  padding: initial;
  justify-content: center;
  margin: 15px 0;
}

.stat-item {
  position: relative;
  height: 160px;
  min-width: 250px;
  padding: 15px 15px;

}
