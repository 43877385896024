@import "colours";
@import "typography";

.pulse-tab {
  background-color: $mc-grey-04;
  padding: 5px 15px;
  color: $mc-blue-01;
  border: 1px solid $mc-grey-04;
  cursor: pointer;
  height: 36px;
  font-family: $header-font;
}

.pulse-tab-content {
  padding: 15px 5px;
  background-color: $mc-grey-04;
  min-height: 550px;
}

.pulse-tab.active {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;
  border: 1px solid $mc-yellow-01;
}

.pulse-nav-tabs {
  margin-top: 10px;
}

.pulse-add-tab-button {
  background: none;
  border: none;
}
