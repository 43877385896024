@import "colours";
@import "typography";

.calendar-wrapper {
  width: auto;
  overflow-x: auto;
}

.full-calendar-wrapper {
  min-width: 1080px;
}

.fc-theme-standard td {
  border: 1px solid $mc-blue-01 !important;
}

.fc-theme-standard th {
  border-bottom: 0 solid transparent;
  border-top: 1px solid $mc-blue-01 !important;
  border-right: 1px solid $mc-blue-01 !important;
  border-left: 1px solid $mc-blue-01 !important;
}

a.fc-col-header-cell-cushion {
  color: $mc-blue-01 !important;
  font-family: $header-font;
}

.fc-daygrid-event {
  border-radius: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $mc-yellow-transparent !important;
}

.fc-daygrid-day-number {
  color: $mc-blue-01 !important;
  font-family: $header-font;
}

.custom-event-tab {
  padding: 5px 5px;
}

.fc-h-event {
  border: none !important;
}
