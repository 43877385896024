@import "colours";
@import "typography";

.group-rostering-system-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, max-content));
  grid-gap: 90px;
  padding: initial;
  justify-content: center;
  margin: 15px 20px;
}

.event-calendar-card {
  background-color: $mc-white-01;
  min-height: 700px;
  width: 375px;
  min-width: 375px;
  border: 1px solid $mc-blue-01;
}
