@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~store-fetch-wrappers/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";
@import "~pulse-modal/dist/styles/scss/index";
@import "~react-horizontal-scrolling-menu/dist/styles.css";

@import "core/animations";
@import "core/availableShiftEntryMobile";
@import "core/buttons";
@import "core/carousel";
@import "core/calendarEntryInputs";
@import "core/calendarGroupCard";
@import "core/colours";
@import "core/dashTiles";
@import "core/dots";
@import "core/dropdown";
@import "core/eventCalendarCard";
@import "core/expandingTableInnerTable";
@import "core/filters";
@import "core/form";
@import "core/fullCalendar";
@import "core/future-month-bar-charts";
@import "core/horizontal-bar-charts";
@import "core/inputs";
@import "core/icons";
@import "core/mobileCalendar";
@import "core/mobileCalendarEvents";
@import "core/modal";
@import "core/navigation";
@import "core/number-bubble";
@import "core/page";
@import "core/react-date-picker";
@import "core/statItem";
@import "core/sidebar";
@import "core/tab";
@import "core/table";
@import "core/toast";
@import "core/toggle";
@import "core/tooltip";
@import "core/typography";
@import "core/utility";
@import "core/variables";
@import "core/weekViewCalendar";
@import "core/xero";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}
