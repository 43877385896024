@import "colours";

.bar-chart-container {
  width: 100%;
  height: 15px;
  //background-color: $mc-grey-01;
  z-index: 1;
}

.bar-unassigned,
.bar-waitingAccept,
.bar-accepted,
.bar-briefed,
.bar-attended {
  transition: all ease-in-out 1s;
  animation-delay: 0.5s;
  height: 100%;
  float: left;
}

.bar-unassigned:first-child,
.bar-waitingAccept:first-child,
.bar-accepted:first-child,
.bar-briefed:first-child,
.bar-attended:first-child {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.bar-unassigned:last-child,
.bar-waitingAccept:last-child,
.bar-accepted:last-child,
.bar-briefed:last-child,
.bar-attended:last-child {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-right: none;
}


.bar-unassigned,
.bar-waitingAccept,
.bar-accepted,
.bar-briefed{
  border-right: 1px solid #ffffff;
}

.bar-base {
  border-radius: 0 !important;
}

.bar-percentage {
  width: var(--bar-percent);
  animation-delay: 3s;
  transition: width 1s;
  transition-delay: var(--animation-delay);
}

.bar-unassigned,
.bar-waitingAccept,
.bar-accepted,
.bar-briefed,
.bar-attended{
  width: var(--bar-percent);
  animation-delay: 3s;
  transition: width 1s;
  transition-delay: var(--animation-delay);
}

.bar-unassigned {
  background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);
}

.bar-waitingAccept {
  background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);
}

.bar-accepted {
  background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);
}

.bar-briefed {
  background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);
}
