@import "colours";
@import "typography";
@import "variables";

.expanding-table-inner-table-header {
  margin: 15px;
  font-family: $body-font;
  font-weight: bold;
}

.expanding-table-inner-table-header.green {
  color: $mc-green-03;
}

.expanding-table-inner-table-header.red {
  color: $mc-red-01;
}

.expanding-table-inner-table-header-item {
  font-family: $header-font;
  font-weight: bolder;
  padding: 0.5rem 1rem;
}

.expanding-table-inner-table-row-item {
  font-family: $body-font;
  padding: 0.5rem 1rem;
  font-size: 0.9em;
}


.inner-table-wrapper {
  width: 100%;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
}

.inner-table-item-header {
  font-family: $body-font;
  font-weight: 600;
}
