@import "colours";

.calendar-group-card {
  width: 225px;
  background-color: $mc-grey-02;
  min-height: 445px;
  height: auto;
  padding: 5px;
}

.group-card-button {
  height: 100px;
  width: 100px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
}
