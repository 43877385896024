@import "colours";
@import "typography";
@import "variables";

.week-view-calendar-wrapper {
  padding-top: 15px;
  display: block;
  overflow-x: auto;
}

.week-view-calendar {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $mc-blue-01;
}

.week-view-calendar-row {
  border-bottom: 1px solid $mc-blue-01;
}

.week-view-calendar-row:last-child {
  border-bottom: none;
}

.week-view-calendar-cell {
  border-right: 1px solid $mc-blue-01;
}
.week-view-calendar-cell:last-child {
  border-right: none;
}

.week-view-calendar-item {
  height: 100px;
  padding: 5px 0 0 5px
}

.week-view-details {
  font-size: 13px;
  font-family: $body-font;
}

.week-view-calendar-item:hover {
  cursor: pointer;
}

.week-view-day {
  background-color: $mc-grey-02;
}

.week-view-day.today {
  background: rgba(112, 229, 186, 0.4);
}
.week-view-day.today:hover {
  background: rgba(112, 229, 186, 0.8);
}

.week-view-day:hover {
  background-color: $mc-grey-04
}

.week-view-day.today:hover,
.week-view-day:hover {
  transition: all ease $animation-duration;
}

.week-view-day.accepted,
.week-view-day.briefing-accepted,
.week-view-day.unallocated,
.week-view-day.assigned {
  color: $mc-white-01;
}

.week-view-day.accepted {
  background-image: linear-gradient(to right, $vibrant-blue, $vibrant-blue-sec);

  &:hover {
    background-image: linear-gradient(to right, lighten($vibrant-blue, 10%), lighten($vibrant-blue-sec, 10%));
    cursor: pointer;
  }
}

.week-view-day.briefing-accepted {
  background-image: linear-gradient(to right, $vibrant-green, $vibrant-green-sec);

  &:hover {
    background-image: linear-gradient(to right, lighten($vibrant-green, 10%), lighten($vibrant-green-sec, 10%));
    cursor: pointer;
  }
}

.week-view-day.unallocated {
  background-image: linear-gradient(to right, $vibrant-red, $vibrant-red-sec);

  &:hover {
    background-image: linear-gradient(to right, lighten($vibrant-red, 10%), lighten($vibrant-red-sec, 10%));
    cursor: pointer;
  }
}

.week-view-day.assigned {
  background-image: linear-gradient(to right, $vibrant-yellow, $vibrant-yellow-sec);

  &:hover {
    background-image: linear-gradient(to right, lighten($vibrant-yellow, 10%), lighten($vibrant-yellow-sec, 10%));
    cursor: pointer;
  }
}

.week-view-calendar-cell-width {
  min-width: 150px !important;
  max-width: 150px !important;
}

.week-day-header-label {
  font-family: $header-font;
}

.compliance-label {
  padding: 5px;
  text-align: right;
  color: $mc-white-01;
  font-size: 0.9rem;
}

.compliance-label.compliant {
  background-color: $mc-green-01;
}

.compliance-label.not-compliant {
  background-color: $mc-red-01;
}

