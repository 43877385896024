
.page {
  min-height: 100vh;
  margin: 0 !important;
}

.main-content-overlay {
  z-index: 1;
  background-color: rgba(227, 227, 227, 0.8) !important;
}
.z-index-fix {
  //position: relative;
  z-index: 1;
}
.main-layout {
  position: relative;
  z-index: 2;
}

.center-items {
  display: grid;
  justify-content: center;
  align-items: center;
}

.background-stars {
  background-image: url(https://medicare.reachdigital.media/wp-content/uploads/2022/03/Asset-4.svg);
}

.background-chevrons {
  background-image: url(https://medicare.reachdigital.media/wp-content/uploads/2022/03/Arrows-Grey.svg);
}

.background-clear {
  background-color: rgb(235, 235, 235);
}

.full-page-bg {
  min-height: 100vh;
}

.background-stars,
.background-arrows,
.background-chevrons {
  background-repeat: repeat!important;
}

.background-overlay {
  background-color: rgba(220, 220, 220, 0.4);
}
