@import "colours";
@import "typography";
@import "variables";

.mobile-calendar-events-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
}

.mobile-calendar-event-item {
  display: flex;
  border-radius: 2px;
  border: 1px solid $mc-grey-04;

  margin: 5px 0;
  padding: 15px 10px;
  box-shadow: $mc-grey-05 0 0 5px 5px;
}

.mobile-calendar-event-item-date-wrapper {
  flex-grow: 1;
  min-width: 60px;
  border-right: 3px solid $mc-blue-02;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-calendar-event-item-day,
.mobile-calendar-event-item-date {
  text-align: center;
}

.mobile-calendar-event-item-day {
  font-size: 12px;
  font-family: $header-font;
}

.mobile-calendar-event-item-date {
  font-size: 22px;
  font-family: $body-font;
}

.mobile-calendar-event-item-details {
  flex-grow: 8;
  padding: 0 5px 0 10px;
}

.mobile-calendar-event-item-detail-item-header {
  font-family: $header-font;
}

.mobile-calendar-event-item-detail-item-body {
  font-family: $body-font;
  color: $mc-blue-03;
  font-size: 14px;
}

.mobile-calendar-event-item-detail-item-group-name {
  font-family: $header-font;
  font-size: 14px;
}

