@import "colours";
@import "typography";

.available-shift-detail-wrapper {
  position: fixed;
  top: 0;
}

.available-shift-calendar-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.available-shift-calendar-info-item {
  display: flex;
  border-radius: 2px;
  border: 1px solid $mc-grey-04;

  margin: 5px 0;
  //padding: 15px 10px;
  box-shadow: $mc-grey-05 0 0 5px 2px;
}

.available-shift-calendar-item-space-wrapper {
  flex-grow: 1;
  min-width: 60px;
  border-right: 3px solid $mc-blue-02;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
}

.available-shift-calendar-event-item-spaces,
.available-shift-calendar-event-item-space-count {
  text-align: center;
}

.available-shift-calendar-event-item-spaces {
  font-size: 12px;
  font-family: $header-font;
}

.available-shift-calendar-event-item-space-count {
  font-size: 22px;
  font-family: $body-font;
}

.available-shift-calendar-event-item-details {
  flex-grow: 6;
  padding: 15px 5px 15px 10px;

}

.available-shift-calendar-event-item-detail-item-header {
  font-family: $header-font;
  font-size: 15px;
}

.available-shift-calendar-event-item-detail-item-body {
  font-family: $body-font;
  color: $mc-blue-03;
  font-size: 12px;
  margin-top: 5px;
}

.available-shift-calendar-event-item-detail-item-group-name {
  font-family: $header-font;
  font-size: 14px;
}

.available-shift-calendar-action-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

.available-shift-calendar-action-button {
  background-color: $mc-blue-01;
  color: $mc-white-01;
  font-size: 12px;
  border: 1px solid transparent;
  height: 100%;
  width: 100%;
  max-width: 75px;
  font-family: $header-font;

  &:hover {
    background-color: $mc-dark-blue-hover;
    border: 1px solid $mc-dark-blue-hover;
  }

  &:disabled {
    background-color: $mc-dark-blue-disabled;
    border: 1px solid $mc-dark-blue-disabled;
  }
}
